import { Link } from "react-router-dom";
import gif from "../static/sad-cry.gif";

export default function NotFound() {

    return (
    <div id="error-page">
      <h1 className=" w-full flex justify-center p-4  text-6xl ">Oops!</h1>
        <p className="flex justify-center ">Sorry, an unexpected error has occurred.</p>
      <div className="flex justify-center">
      <img src={gif}/>  
      </div>
      <div className="flex justify-center">
        <Link className='bg-blue-500 text-white px-8 py-3 rounded-md mt-5' to='/'>Home</Link>
      </div>
    </div>
    )
}