import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import '../css/home.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Home() {
  const [inputText, setInputText] = useState('');
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupShown, setPopupShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const url = query.get('videoUrl');
    if (url && !popupShown) {
      setVideoUrl(url);
      setShowPopup(true);
      setPopupShown(true); // Prevent the popup from showing again
    }
  }, [query, popupShown]);

  useEffect(() => {
    const adScript = document.createElement('script');
    adScript.async = true;
    adScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1671995970703431";
    adScript.crossOrigin = "anonymous";
    document.head.appendChild(adScript);

    return () => {
      document.head.removeChild(adScript);
    };
  }, []);

  const isValidUrl = (url: string) => {
    const regex = /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[a-zA-Z0-9_]{1,15}\/status\/[0-9]+$/;
    return regex.test(url);
  };

  const handleConvert = async () => {
    setErrorMessage(null); // Clear any previous error message
    setVideoUrl(null); // Clear any previous video URL
    setIsLoading(true); // Set loading state to true
    console.log(`Converting link: ${inputText}`);

    if (!isValidUrl(inputText)) {
      setErrorMessage('Invalid Twitter or X video link. Please provide a valid link.');
      setIsLoading(false);
      return;
    }

    try {
      const { data } = await axios.post(`https://twitterbot.otools.site:3001/convert`, { link: inputText });
      setVideoUrl(data.videoUrl);
      console.log(`Video URL received: ${data.videoUrl}`);
      setShowPopup(true); // Show the popup
    } catch (error) {
      console.error('Error converting:', error);
      setErrorMessage('Error converting link. Please try again.');
    } finally {
      setIsLoading(false); // Set loading state to false
    }    
  };

  const handleDownload = async () => {
    if (videoUrl) {
      try {
        const response = await fetch(videoUrl);
        const blob = await response.blob();
        
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'DownloadedVideo.mp4');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading video:', error);
      }
    }
  };

  const handleNewConversion = () => {
    navigate('/');
    setInputText('');
    setVideoUrl(null);
    setErrorMessage(null);
    setShowPopup(false); // Hide the popup
    setPopupShown(false); // Reset the popup shown state
  };

  return (
    <div className='flex flex-col justify-center items-center h-screen text-white home'>
      {!videoUrl && (
        <>
          <h1 className='text-2xl mb-4'>Insert Twitter or X video link:</h1>
          <input
            type='text'
            name='convertForm'
            className='text-white p-2 rounded-md w-2/4 mb-4 bg-blue-800'
            placeholder='Enter Twitter or X video link'
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
          <button
            type='button'
            className='bg-blue-500 text-white px-4 py-2 rounded-md'
            onClick={handleConvert}
            disabled={isLoading} // Disable the button while loading
          >
            {isLoading ? (
              <div className='loader' /> // Show loading spinner
            ) : (
              'Convert'
            )}
          </button>
        </>
      )}
      {errorMessage && (
        <div className='bg-red-500 text-white px-4 py-2 rounded-md mt-4'>
          {errorMessage}
        </div>
      )}
      {videoUrl && (
        <div className='mt-4'>
          <video
            controls
            className='video-preview'
          >
            <source src={videoUrl} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <button
            className='bg-green-500 text-white px-4 py-2 rounded-md mt-4 block text-center'
            onClick={handleDownload}
          >
            Download Video
          </button>
          <button
            className='bg-blue-500 text-white px-4 py-2 rounded-md mt-4 block text-center'
            onClick={handleNewConversion}
          >
            Convert a New Video
          </button>
        </div>
      )}
      {showPopup && (
        <div className='fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center'>
          <div className='bg-white p-6 rounded-lg text-black'>
            <h2 className='text-2xl mb-4'>Conversion Successful</h2>
            <ins className="adsbygoogle"
                 style={{ display: 'block' }}
                 data-ad-client="ca-pub-1671995970703431"
                 data-ad-slot="1234567890"
                 data-ad-format="auto"></ins>
            <button
              className='bg-red-500 text-white px-4 py-2 rounded-md mt-4 block text-center'
              onClick={() => setShowPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
