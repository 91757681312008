import React from 'react'
/**
 * * Library imports
 */
// ? https://www.npmjs.com/package/react-router-dom
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import "./index.css";
import Navbar from './components/Navbar';
import Home from './views/Home';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
function App() {
  return (
    <Router>
      <Navbar />
      <div>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path='*' element={<NotFound />}/>
        </Routes>
      </div>
      <Footer />
    </Router>
  )
}

export default App
