import React from 'react';
import logo from '../static/TwitterDownloadLogo.jpeg';

function Navbar() {
  return (
    <div className='shadow-md w-full top-0 left-0 flex items-center p-4 bg-blue-600'>
      <a href='/' className='flex items-center bg-blue-600'>
        <img src={logo} alt='Logo' className='w-20 h-20 rounded-xl mr-4 bg-blue-600'/>
      </a>
      <div className='flex-1 flex justify-center bg-blue-600'>
        <h1 className='text-white text-2xl font-bold mr-20 bg-blue-600'>TwitterDownloadBot</h1>
      </div>
    </div>
  );
}

export default Navbar;
