import React from 'react';

function Footer() {
  return (
    <footer className=" text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <section className="w-full md:w-1/3 mb-6 md:mb-0">
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ol className="list-decimal list-inside">
              <li className="mb-2"><a href="/#home" className="hover:underline">Home</a></li>
            </ol>
          </section>
          
          <section className="w-full md:w-1/3 mb-6 md:mb-0">
            <h4 className="text-lg font-semibold mb-4">Legal</h4>
            <ul className="list-none">
              <li className="mb-2"><a href="https://policies.google.com/terms" className="hover:underline">Terms of Service</a></li>
              <li className="mb-2"><a href="https://policies.google.com/privacy" className="hover:underline">Privacy Policy</a></li>
            </ul>
          </section>
          
          <section className="w-full md:w-1/3">
            <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
            <p className="mb-2">Email: twitterdownloadbot@gmail.com</p>
            <ul className="flex space-x-4">
              <li><a href="https://x.com/Downloadvide" className="hover:underline">Twitter / X</a></li>
            </ul>
          </section>
        </div>
      </div>
      <p className="text-center mt-8">© {new Date().getFullYear()} OTools. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
